import axios from 'axios'
import isAuthenticate from './auth'

export default axios.create({
    baseURL : process.env.REACT_APP_BASE_URL,
    responseType : 'json',
    headers: {'Authorization': `Bearer ${isAuthenticate() ? isAuthenticate() : ''}`,'domain' :  'harnesshorse.moblearn.net' }
})

//window.location.host

