import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Routes/Index";
import GlobalContext from "./Context/GlobalContext";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
} from "react-router-dom";
import getCategoreyDetails from "./APIS/GetCategorey";

import isAuthenticate from "./utils/auth";
import { Category } from "@material-ui/icons";
import { userAllDetails } from "./APIS/User";
import { getUserWishList } from "./APIS/Products";
import { subscribeToTimer } from "./APIS/Socket";
import ScrollToTop from "./Routes/scrollToTop";
import axios from "./utils/axiosConfig";
import { useCookies } from 'react-cookie';

function App() {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [getCat, setCat] = useState({});
  const [isUserAuthenticate, setIsUserAuthenticate] = useState(
    isAuthenticate()
  );
  const [productFilter, setProductFilter] = useState({
    filter: {
      page: 1,
      sh_limit: 100,
      fromprice: 0,
      toprice: 50000,
      orderby: 2,
      min: 0,
      max: 50000,
      highPrice: null,
      from: "both",
    },
    wishList: [],
  });
  const [socketTime, setSocketTime] = useState("");
  const [cookies, setCookie] = useCookies(['user_session']);
  if (cookies.user_session && cookies.user_session != ""){
  } else {
    const session_id = Math.floor(100000 + Math.random() * 900000)
    setCookie('user_session', session_id, { path: '/' });
  }
  const getMinMax = async () => {
    try {
      const res = await axios.post("mobileapi/mobilesearch", {});
      if (
        res.data.success === "yes" &&
        res.data.maxpriceprojects !== "" &&
        res.data.minpriceprojects !== ""
      ) {
        setProductFilter({
          ...productFilter,
          filter: {
            ...productFilter.filter,
            // fromprice: 0,
            // toprice: 50000,
            highPrice: res.data.maxpriceprojects,
            // min: parseInt(0),
            // max:
            //   parseInt(res.data.maxpriceprojects) > 500
            //     ? 500
            //     : parseInt(res.data.maxpriceprojects),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMinMax();
    const getCatData = async () => {
      let data = await getCategoreyDetails();
      typeof data !== "string" && setCat(data);
    };
    subscribeToTimer((err, serverTime) => {
      if (!err) {
        setSocketTime(serverTime);
      }
    });
    const clientDetails = async () => {
      if (isAuthenticate()) {
        const data = await userAllDetails();
        if (typeof data !== "string") {
          setUserDetails(data);
          //let wishList = await getUserWishList(data["id"]);
          setProductFilter({ ...productFilter, ["wishList"]: [] });
        }
      }
    };
    getCatData();
    clientDetails();
  }, []);

  const store = {
    loading: { get: loading, set: setLoading },
    userDetails: { get: userDetails, set: setUserDetails },
    token: { get: isUserAuthenticate, set: setIsUserAuthenticate },
    category: { get: getCat },
    productFilter: { get: productFilter, set: setProductFilter },
    serverTime: { get: socketTime, set: setSocketTime },
  };

  return (
    <div className="App">
      <GlobalContext.Provider value={store}>
        <Router>
          <ScrollToTop />
          <Routes />
        </Router>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
