import axios from "../utils/axiosConfig";
import { findIndex } from "lodash";

const getProductDetails = async (body, filterData) => {
  let { data } = await axios.post("mobileapi/getprodetails", body);
  try {
    if (data.success === "yes") {
      let attachments = data.attachments.map((data) => ({
        original: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${data.src}`,
        thumbnail: `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${data.src}`,
      }));
      let items = data.attachments.map(
        (data) => process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
      );
      let bidDetails = data.biddetails;
      let results = data.results;
      let optionalDetails = JSON.parse(results.custom_field_8);
      results["videos"] = results["videos"]
        ? `${process.env.REACT_APP_BASE_URL}uploads/${results.videos}`
        : "";
      results["custom_field_8"] = optionalDetails;
      let catPosition = findIndex(filterData["typesOfHorse"], {
        id: parseInt(results["category_id"]),
      });
      if (catPosition !== -1) {
        results["category_id"] =
          filterData["typesOfHorse"][catPosition]["title"];
      }
      let locationIndex = findIndex(filterData["location"], {
        id: parseInt(results["state"]),
      });
      if (locationIndex !== -1) {
        results["state"] = filterData["location"][locationIndex]["title"];
      }
      let genderPosition = findIndex(filterData["gender"], {
        id: parseInt(results.content_head3),
      });
      if (genderPosition !== -1) {
        results["content_head3"] =
          filterData["gender"][genderPosition]["title"];
      }
      let gaitPosition = findIndex(filterData["gait"], {
        id: parseInt(results.content_head2),
      });
      if (gaitPosition !== -1) {
        results["content_head2"] = filterData["gait"][gaitPosition]["title"];
      }
      return { ...results, attachments, bidDetails, items };
    }
  } catch (err) {
    console.log("getProductDetails err====>", err);
    return "Something went wrong";
  }
};

const getSearchDetails = async (body, filterData) => {
  let datas = body;
  if (datas.fromprice === datas.min) {
    datas.fromprice = "";
  } else {
    datas.fromprice = datas.fromprice;
  }
  if (datas.toprice === 50000) {
    datas.toprice = filterData.highPrice;
  } else {
    datas.toprice = datas.toprice;
  }

  //console.log(body);

  let { data } = await axios.post("mobileapi/mobilesearch", body);
  try {
    if (data.success === "yes" && typeof data.results !== "string") {
      if (body.orderby === 6) {
        var buynow = [];
        var auction = [];
        data.results.map((value) => {
          if (value.buynow) {
            buynow.push(value);
          } else {
            auction.push(value);
          }
        });
        data.results = auction.concat(buynow);
      }
      if (body.orderby === 3) {
        data.results.sort(function (a, b) {
          return a.wprice - b.wprice;
        });
      }
      if (body.orderby === 4) {
        data.results.sort(function (a, b) {
          return b.wprice - a.wprice;
        });
      }

      //   if(body.orderby==1){

      //   data.results.reverse();
      //  //reversing for Endidng soon, since by default the sorting is in reverse
      //   }
      //   console.log("Rsults -->")
      //   console.log(data.results);

      //  var buynowresults=[];
      //   //remove buynow results and add buynow results at the end
      //   var r=0;
      //   for(r=0; r<data.results.length ; r++){
      //     if(data.results[r].buynow==1){
      //       buynowresults.push(data.results[r]);
      //       data.results.splice(r,1);
      //     }
      //   }
      //   data.results=data.results.concat(buynowresults);
      //console.log("Rsults after buynow process -->");
      //console.log(data.results);
      let product = data.results.map((data) => {
        let products = data;
        let locationPosition = findIndex(filterData["location"], {
          id: parseInt(products.productLocation),
        });
        if (locationPosition !== -1) {
          products = {
            ...products,
            productLocation: filterData["location"][locationPosition]["title"],
          };
        }
        let typePosition = findIndex(filterData["typesOfHorse"], {
          id: parseInt(products.category_id),
        });
        if (typePosition !== -1) {
          products = {
            ...products,
            category_id: filterData["typesOfHorse"][typePosition]["title"],
          };
        }
        let genderPosition = findIndex(filterData["gender"], {
          id: parseInt(products.content_head3),
        });
        if (genderPosition !== -1) {
          products = {
            ...products,
            content_head3: filterData["gender"][genderPosition]["title"],
          };
        }
        let gaitPosition = findIndex(filterData["gait"], {
          id: parseInt(products.content_head2),
        });
        if (gaitPosition !== -1) {
          products = {
            ...products,
            content_head2: filterData["gait"][gaitPosition]["title"],
          };
        }
        return products;
      });
      return { products: product, count: data.total_pagecnt };
    } else {
      return { products: [], count: 0 };
    }
  } catch (err) {
    console.log("getSearchDetails err====>", err);
    return { products: [], count: 0 };
  }
};

const getUserWishList = async (userId) => {
  let { data } = await axios.get(`mobileapi/userWlistData/${userId}`);
  try {
    return data.success === "yes" ? data.userWlistData : [];
  } catch (err) {
    console.log("getUserWishList err====>", err);
    return [];
  }
};

export { getSearchDetails, getUserWishList, getProductDetails };
