import axios from "../utils/axiosConfig";
import _, { constant } from "lodash";
import constants from "../utils/Constants";

const getCategoreyDetails = async () => {
  let { data } = await axios.get("mobileapi/getoverallcatgeory");
  try {
    if (data.success === true) {
      let overAllCat = {};
      const positionOfTypes = _.findIndex(data.category_val, {
        name: "Types of horse",
      });
      if (positionOfTypes !== -1) {
        overAllCat = {
          ...overAllCat,
          typesOfHorse: data.category_val[positionOfTypes][
            "filters"
          ].map((data) => ({ id: data.id, title: data.name })),
        };
      }
      const positionOfGait = _.findIndex(data.category_val, { name: "Gait" });
      if (positionOfGait !== -1) {
        overAllCat = {
          ...overAllCat,
          gait: data.category_val[positionOfGait]["filters"].map((data) => ({
            id: data.id,
            title: data.name,
          })),
        };
      }
      const positionofGender = _.findIndex(data.category_val, {
        name: "Gender",
      });
      if (positionofGender !== -1) {
        overAllCat = {
          ...overAllCat,
          gender: data.category_val[positionofGender][
            "filters"
          ].map((data) => ({ id: data.id, title: data.name })),
        };
      }
      const positionOfLocation = _.findIndex(data.category_val, {
        name: "Location",
      });
      if (positionOfLocation !== -1) {
        overAllCat = {
          ...overAllCat,
          location: data.category_val[positionOfLocation][
            "filters"
          ].map((data) => ({ id: data.id, title: data.name })),
        };
      }
      if (constants.age)
        return (overAllCat = { ...overAllCat, age: constants.age });
    } else {
      return "something went wrong";
    }
  } catch (err) {
    console.log("getCategoreyDetails=====>", err);
    return "something went wrong";
  }
};

export default getCategoreyDetails;
