import React from 'react'
import {Route,Redirect} from 'react-router'
import isAuthenticate from '../utils/auth'

const PublicRoute = ({ component: Component, ...rest }) =>{
    return(
        <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
      }
    />
    )
}

const PrivateRoute = ({ component: Component, ...rest }) =>(
    <Route
      {...rest}
      render={(props) =>
        isAuthenticate() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
)

const RestrictedRoute = ({ component: Component, ...rest }) =>(
    <Route
      {...rest}
      render={(props) =>
        isAuthenticate() === undefined ? <Redirect to="/" /> : <Component {...props}/>}
    />
)



export {PublicRoute,PrivateRoute,RestrictedRoute}