import { initial } from "lodash";
import io from "socket.io-client";
const socket = io(process.env.REACT_APP_BASE_URL, { jsonp: false, transports: ['websocket'] });

var firstcon=true;
const subscribeToTimer = (cb) => {
  socket.on("connect", () => {
    socket.on("sliservertime", function (timestamp) {
      let { dTime: serverTime } = timestamp;
      cb(null, serverTime);
    });
  });
};

const subscribeToBidding = (cb) => {
  //console.log("socket isConnected===>",socket.connected)
  if(firstcon){
    socket.on("bidAddtime", function (data) {
      console.log("bidAddTime==>",data)
     
      cb(null, data);
    });
  }
  firstcon=false;
};

const extendedBidSocket= (cb) =>{
  
  socket.on("bid_extended", function (data) {
    console.log("bidextended==>",data)
  
    cb(null, data);
  });
}

/* New Sockets starts here*/

// bidAddtime socket is already defined as subscribeToBidding but I created this without the variable firstcon
const bidAddtime = (cb) => {
  socket.on('bidAddtime', (data) => {
    cb(data);
  });
}

// Bid close socket
const realClosedUpdates = (cb) => {
  socket.on('realclosedupdates', (data) => {
    cb(data);
  });
}
/* New Sockets ends here*/


export { subscribeToTimer,subscribeToBidding,extendedBidSocket, bidAddtime, realClosedUpdates };
