import axios from "../utils/axiosConfig";
import isAuthenticate from "../utils/auth";
import JwtDecode from "jwt-decode";
import { isMacOs, isWindows, isAndroid, isIOS, isChrome, isFirefox, isSafari, isIE, isEdge } from 'react-device-detect';
const publicIp = require('public-ip');

const userAllDetails = async () => {
  const res = await axios.post("mobileapi/getUserProfileDetails", {});
  try {
    if (res.data.success === "yes") {
      return res.data.data;
    } else {
      return `something went wrong`;
    }
  } catch (err) {
    console.log(err);
    
  }
};

const userLogin = async (body) =>{
    const {data} = await axios.post('mobileapi/login',body)
    try{
       if(data.status === 'yes'){
        const token = data.data
        localStorage.setItem('userToken',token)
        axios.defaults.headers.common['Authorization'] =  `Bearer ${token}`
        return data;
       }else{
        return data.message;
       } 
    }catch(err){
        console.log("userLogin err===>",err)
        return `something went wrong`;
    }
}

const buyerTransactions = async() => {
const res = await axios.post('mobileapi/buyerTransaction')
try{
  if (res.data.success === 'yes'){
    return res.data.results;
  }else{
    return 'something went wrong'
  }
}catch(err){
  console.log(err);
}
};

const userActivity = async (page, session_id) => {

  console.log('userActivity',page, session_id)
  
  var userid = isAuthenticate() ? JwtDecode(isAuthenticate()).id : 0
  let userip = await publicIp.v4();
  var OsTypes = "Others"
  var BrowserTypes = "Others"

  if (isMacOs) {
    OsTypes = "Mac OS"
  } else if (isWindows) {
    OsTypes = "Windows"
  } else if (isAndroid) {
    OsTypes = "Android"
  } else if (isIOS) {
    OsTypes = "iOS"
  }
  if (isChrome) {
    BrowserTypes = "Chrome"
  } else if (isSafari) {
    BrowserTypes = "Safari"
  } else if (isFirefox) {
    BrowserTypes = "Firefox"
  } else if (isIE) {
    BrowserTypes = "Internet Explorer"
  } else if (isEdge) {
    BrowserTypes = "Edge"
  }
    

  var params = {
    userid: userid,
    session_id: session_id,
    ipaddress: userip,
    page_viewed: page,
    system: OsTypes,
    browser: BrowserTypes,
    link: window.location.href,
  }
  axios.post('mobileapi/userActivity', params);
}

export { userAllDetails,userLogin,buyerTransactions,userActivity };
