const constants ={
    onlyNumbers : '^[0-9]+$',
    imageFormats : ['cc','image/jpeg','image/png', 'image/heic', 'image/heif', 'image/webp', 'webp'],
    videoFormats : ['video/mp4'],
    docFormats : ['application/pdf'],
    typeOfSales : [{id : "auction",title : "Auction"},{id : "buynow", title : "Listing"}],
    age : [
        {
          id : '0',
          title : '0 years'
        },
        {
          id : '1',
          title : '1 years'
        },
        {
          id : '2',
          title : '2 years'
        },
        {
          id : '3',
          title : '3 years'
        },
        {
          id : '4',
          title : '4 years'
        },
        {
          id : '5',
          title : '5 years'
        },
        {
          id : '6',
          title : '6 years'
        },
        {
          id : '7',
          title : '7 years'
        },
        {
          id : '8',
          title : '8 years'
        },
        {
          id : '9',
          title : '9 years'
        },
        {
          id : '10',
          title : '10 years'
        },
        {
          id : '11',
          title : '11 years'
        },
        {
          id : '12',
          title : '12 years'
        },
        {
          id : '13',
          title : '13 years'
        },
        {
          id : '14',
          title : '14 years'
        },
        {
          id : '15',
          title : '15 years'
        },
        {
          id : '16',
          title : '16 years'
        },
        {
          id : '17',
          title : '17 years'
        },
        {
          id : '18',
          title : '18 years'
        },
        {
          id : '19',
          title : '19 years'
        },
        {
          id : '20',
          title : '20 years'
        },
        {
          id : '21',
          title : '22 years'
        },
        {
          id : '23',
          title : '23 years'
        },
        {
          id : '24',
          title : '24 years'
        },
        {
          id : '25',
          title : '25 years'
        },
        {
          id : '26',
          title : '26 years'
        },
        {
          id : '27',
          title : '27 years'
        },
        {
          id : '28',
          title : '28 years'
        },
        {
          id : '29',
          title : '29 years'
        },
        {
          id : '30',
          title : '30 years'
        },
      ]
}

export default constants