import React, { useEffect, useContext, Suspense, lazy } from "react";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
} from "react-router-dom";
import { PublicRoute, PrivateRoute, RestrictedRoute } from "./TypedRoute";
const Signup = lazy(() => import("../View/Signup"));
const ForgotPassword = lazy(() => import("../View/ForgotPassword"));
const ResetPassword = lazy(() => import("../View/ResetPassword"));
const Contactus = lazy(() => import("../View/Contactus"));
const Profile = lazy(() => import("../View/Profile"));
const Mypurchases = lazy(() => import("../View/Mypurchases"));
const MyAuctions = lazy(() => import("../View/MyAuctions"));
const Favourites = lazy(() => import("../View/Favourites"));
const PostProject = lazy(() => import("../View/PostProject"));
const Notifications = lazy(() => import("../View/Notifications"));
const BuyerInvoice = lazy(() => import("../View/Invoice/BuyerInvoice"));
const Payments = lazy(() => import("../View/Payments"));
const ProductView = lazy(() => import("../View/ProductView/index copy"));
const TermsConditions = lazy(() => import("../View/StaticPage/Termsandconditions"));
const PasswordUpdate = lazy(() => import("../View/ForgotPassword/forgotpassword"));
const Verification = lazy(() => import("../View/Signup/verification"));
const Aboutus = lazy(() => import('../View/StaticPage/aboutus'));
const Resources = lazy(() => import('../View/Resources'));
const PrivacyPolicy = lazy(() => import('../View/StaticPage/privacypolicy'));
const Login = lazy(() => import("../View/Login"));
const Search = lazy(() => import("../View/Search"));
const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Page is Loading...</div>}>
    <Switch>
      <Route exact path="/PrivacyPolicy" component={PrivacyPolicy } />
      <PrivateRoute path="/invoice/:type/:id" component={BuyerInvoice}/>
      <PrivateRoute exact path="/payments" component={Payments}/>
      <Route exact path="/aboutus" component={Aboutus}/>
      <Route exact path="/resources" component={Resources}/>
      <PrivateRoute exact path="/notifications" component={Notifications} />
      <Route exact path="/verifyUser" component={Verification} />
      <Route exact path="/login/forgot_password" component={PasswordUpdate} />
      <Route exact path="/termsconditions" component={TermsConditions} />
      <Route exact path="/productView/:id" component={ProductView} />
      <PrivateRoute exact path="/postproject" component={PostProject} />
      <PrivateRoute exact path="/editproject/:id" component={PostProject} />
      <PrivateRoute exact path="/favorites" component={Favourites} />
      <PrivateRoute exact path="/my-auctions" component={MyAuctions} />
      <PrivateRoute exact path="/my-purchases" component={Mypurchases} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PublicRoute exact path="/contactus" component={Contactus} />
      {/* <PublicRoute exact path="/search" component={Search} /> */}
      <RestrictedRoute exact path="/reset-password" component={ResetPassword} />
      <RestrictedRoute
        exact
        path="/forgot-password"
        component={ForgotPassword}
      />
      <RestrictedRoute exact path="/login" component={Login} />
      <RestrictedRoute exact path="/register" component={Signup} />
      <PublicRoute exact path="/" component={Search} />
    </Switch>
    </Suspense>
    </Router>
  );
};

export default withRouter(Routes);

  